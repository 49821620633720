.covid {
    max-width: 40rem;
    margin: 2rem auto;
    background-color: #fff;
    color: $color-blue;
    padding: 2rem;
    border: 6px solid $color-blue;
    border-radius: 6px;
    a {
        color: $color-blue;
        text-decoration: underline;
    }
}

.hp_herobar-logo-wrap .covid a {
    color: $color-blue;
    text-decoration: underline;
}