button, .button {
    padding: 0.75em 2em;
    background-color: $color-blue;
    text-shadow: none;
    color: $color-white;
    text-decoration: none;
    border: 2px solid $color-white;
    font-weight: 300;
    transition: all 0.25s ease;
    &:hover {
        text-decoration: none;
        background-color: lighten($color-blue, 15%);
        color: $color-white;
        cursor: pointer;
    }
}

.button {
    display: inline-block;
}

.button-link {
    border: none;
    padding: 0;
    border-radius: 0;
    background-color: transparent;
    color: $color-blue;
    font-weight: 700;
    &:hover {
        background-color: transparent;
        color: lighten($color-blue, 15%);
    }
}

.button-inline {
    margin: 0;
}

.button-small {
    font-size: 0.75em;
    padding: 0.75em 1em;
}

.button-transparent {
    background-color: transparent;
    border-color: $color-blue-light;
    color: $color-blue-light;
    font-weight: 400;

    &:hover {
        text-decoration: none;
        border-color: lighten($color-blue, 10%);
        background-color: rgba($color-blue-light, 0.05);
        color: lighten($color-blue, 10%);
    }

}

.button-wrap {
    text-align: center;
    .button {
        @include mq(tablets) {
            margin-right: 2em;
            &:last-of-type {
                margin-right: 0;
            }
        }
    }
}