.footer {
    padding: 0 1em 1em;
    border-top: 1px solid rgba($color-blue, 0.3);
    @include mq(tablets) {
        padding-top: 1em;
    }
    @include mq(73.25em) {
        padding: 1em 0;
    }

}

.footer_content-wrap {
    max-width: $max-width;
    margin: 0 auto 1em;
    padding-bottom: 1em;
    border-bottom: 1px solid rgba($color-blue-light, 0.3);
    text-align: center;
    @include mq(tablets) {
        display: flex;
        text-align: left;
    }
}

.footer_content-heading {
    margin-top: 3em;
    @include mq(tablets) {
        margin-top: 0;
    }
}

.footer-copyright-wrap {
    text-align: center;
}

.footer-logo_wrap {
    margin: 3em auto;
    padding: 0 1em;

    @include mq(tablets) {
        margin: 0 3em 0 0;
        padding: 0;
    }
}

.form-footer {
    width: 95%;

    .form-field_wrap {
        text-align: left;
        margin: 0 auto;
        @include mq(tablets) {
            display: flex;
            align-items: flex-end;
            margin-bottom: 1em;

            label {
                display: inline-block;
                width: 20%;
            }

            @include text-input() {
                padding: 0.5em;
                border: 1px solid rgba($color-grey-dark, 0.15);
                border-radius: $border-radius;
                background-color: rgba(255, 255, 255, 0.9);
            }
        }

    }


}

.footer-social_bar {
    margin-top: 2em;
    .social_icon-item {
        width: 1.75em;
        height: 1.75em;
        fill: $color-blue-light;
        transition: all 0.25s ease;
        text-decoration: none;

        &:hover {
            fill: darken($color-blue-light, 10%);
        }
    }
}