.alert {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    min-width: 10rem;
    margin: 0 auto 1em;
    padding: 0.5em;
    background-color: rgba(0, 0, 0, 0.05);
    border-left: 10px solid transparent;
    @include mq(tablets) {
        width: 70%;
    }

    // alerts nested inside forms should go the full width
    .form-form_wrap & { width: 100%; }
}

.alert--success {
    border-left-color: $status--success;
}
.alert--info {
    border-left-color: $status--info;
}
.alert--warning {
    border-left-color: $status--warning;
}
.alert--danger {
    border-left-color: $status--danger;
}

// @todo implement dismiss message (JS)
//.alert-action {
//    // for <=IE9
//    float: right;
//    padding-left: 1em;
//    font-size: 0.8em;
//    color: rgba($link-color, 0.5);
//    &:hover { color: rgba($link-color, 1); }
//}