// mixins + normalize + common
@import "common/vars", "public/vars", "common/mixins", "public/mixins", "common/media", "common/common";

// global styles on the most common elements
html {
    font: 100%/1 'Zilla Slab', Helvetica, Arial, sans-serif;
    line-height: 1.5;
    color: $text-color;
    max-width: 100vw;
    overflow-x: hidden;
}

a {
    transition: color 0.25s ease;
    text-decoration: none;
    color: $color-blue-light;
    &:hover {
        text-decoration: underline;
    }
}

.contact-map-wrap {
    .cmsmap {
        max-width: 100%;
        margin-bottom: 3em;
    }
}

.-list-reset {
    margin: 0;
    padding:0;
    list-style: none;
}

// load common styles
@import "common/forms", "common/pagination", "common/alerts";

// public only styles
@import "public/content",
    "public/buttons",
    "public/social",
    "public/header",
    "public/herobar",
    "public/footer",
    "public/headings",
    "public/bios",
    "public/subnav",
    "public/covid";

// page specific
@import "public/p_index", "public/p_faq", "public/p_services", "public/p_team";