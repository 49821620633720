.hp_herobar-logo-wrap {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 2em;
    background-color: rgba(0,0,0, 0.3);

    a {
        color: $color-white;
        font-weight: 700;
        text-decoration: none;
        text-align: center;
    }

    @include mq(tablets) {
        height: 28em;
        margin: 5em 0;
    }
}

.hp_herobar-logo {
    float: left;
    @include clearfix;
    flex: 0 0 90%;
    width: 90%;
    max-width: 90%;
    margin: 0 auto 1em;
    transition: all 0.5s ease;
    padding: 1em;
    border: 3px solid $color-white;
    border-radius: 6px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.9px;

    &:hover {
        transform: scale(1.1);
    }

    @include mq(tablets) {
        flex: 0 0 45%;
        width: 45%;
        max-width: 45%;
        margin: 0;

        &:first-of-type {
            margin-right: 3em;
        }
    }
}

.hp_herobar-heading {
    width: 100%;
    flex: 1 1 100%;
    font-size: 1.5em;
    margin-bottom: 1.5em;
    text-align: center;

    @include mq(tablets) {
        font-size: 2.25em;
    }
}

.hp-logo-wrap {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    max-width: 40em;
    width: 100%;
    margin: 0 auto 2em;
}

.hp_icons-wrap {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    max-width: 65em;
    margin: 0 auto 4em;
    padding: 0;
    list-style: none;

    @include mq(wide_tablets) {
        margin: 4em auto 5em;
    }
}

.hp-icon {
    width: 60%;
    max-width: 60%;
    flex: 1 1 60%;
    margin: 0 auto;
    padding: 3em 0;
    text-align: center;
    border-bottom: 3px solid $color-white;
    transition: all 0.5s ease;

    @include mq(tablets) {
        float: left;
        @include clearfix;
        width: 29%;
        flex: 0 0 29%;
        max-width: 29%;
        margin: 0 3em 0 0;
        padding: 2em 2em 1em;
        border: 3px solid transparent;
        &:last-of-type {
            margin-right: 0;
        }
    }
    &:hover {
        border-color: $color-white;

    }

    svg {
        max-width: 8em;
        fill: $color-white;
        @include mq(tablets) {
            max-width: 12em;
        }
    }
}

.hp-icon-contact {
    border: none;

    svg {
        max-width: 7em;
    }

    a {
        color: $color-white;
    }
}

.hp-icon-resources svg {
    padding-left: 2em;
}

.hp_icon-heading {
    color: $color-white;
}