.social_icon-item-link:hover {
    text-decoration: none;
}

 .social_icon-item-link {
    margin-right: 0.5em;
    text-decoration: none;

    &:last-of-type {
        margin-right: 0;
    }
}

.social_icon-item {
    width: 1.75em;
    height: 1.75em;
    fill: $color-grey-light;
    transition: all 0.25s ease;
    text-decoration: none;

    &:hover {
        fill: lighten($color-grey-light, 10%);
    }
}