h1, h2, h3, h4, h5, h6 {
    line-height: 1.2;
    letter-spacing: 1.1px;
}

h1 {
    font-size: 2.9em;
    @include mq(tablets) {
        font-size: 3.7em;
    }

}

h2 {
    font-size: 2.3em;
}

h3 {
    font-size: 1.5em;
    font-weight: 100;
    @include mq(tablets) {
        font-size: 1.75em;
    }
}

h4 {
    font-size: 1.2em;
    font-weight: 100;
    margin-bottom: 0.5em;
}

h5 {
    margin-bottom: 0.5em;
    font-weight: 700;
}
