/* global variables */
$max-width: 71.25em;
$max-width-narrow: $max-width - 20;

$color-white: rgb(255, 255, 255);
$color-black: rgb(0 ,0, 0);
$color-grey-light: rgb(243, 243, 243);
$color-grey-dark: rgb(32, 36, 41);
$color-red: rgb(247, 81, 70);
$color-blue-light: rgb(64, 80, 107);
$color-blue: rgb(14, 37, 70);

$text-color: #444;
$link-color: rgb(1, 142, 66);
$headings-color: $link-color;

$border-radius: 2px;

$status--success: rgb(90, 182, 90);
$status--info: rgb(91, 192, 221);
$status--warning: rgb(240, 173, 78);
$status--danger: rgb(217, 82, 78);

$pagination-link-hover: rgba(140, 195, 63, 0.4);
$pagination-current-border: rgba(32, 36, 41, 0.15);
$pagination-item-inactive: lighten($text-color, 50%);

$mqs: (
    handhelds: 20em,
    wide_handhelds: 35em,
    tablets: 48em,
    wide_tablets: 64em
);