.content-wrap {
    @include clearfix;
    max-width: $max-width;
    margin: 0 auto;
    padding: 1em;

    @include mq($max-width) {
        padding: 1em 0;
    }

    &-medium {
        max-width: 60em;
    }

    &-narrow {
        max-width: 40em;
    }

    &-flex {
        display: flex;
        flex-flow: column nowrap;
        @include mq(tablets) {
            flex-flow: row nowrap;
            justify-content: space-between;
            width: 100%;

        }
    }
}

.flex-child {
    float: left;
    @include clearfix;
    &-border {
        padding-top: 2em;
        padding-bottom:  2em;
        border-bottom: 2px solid $color-blue-light;
        text-align: center;
        @include mq(tablets) {
            padding-right:  2em;
            border-right: 2px solid $color-blue-light;
            padding-top: 0;
            padding-bottom:  0;
            border-bottom: none;
            text-align: left;
        }

        &:last-of-type {
            border: none;
        }

    }

    &-25 {
        @include mq(tablets) {
            float: left;
            @include clearfix;
            flex: 0 1 23%;
            width: 23%;
            max-width: 23%;
            margin-right: 2em;
            &:last-of-type {
                margin-right: 0;
            }
        }
    }

    &-33 {
        @include mq(tablets) {
            float: left;
            @include clearfix;
            flex: 0 1 32%;
            width: 32%;
            max-width: 32%;
            margin-right: 2em;
            &:last-of-type {
                margin-right: 0;
            }
        }
    }

    &-48 {
        @include mq(tablets) {
            float: left;
            @include clearfix;
            flex: 0 1 48.5%;
            width: 48.5%;
            max-width: 48.5%;
            &:first-of-type {
                margin-right: 2em;
            }
        }
    }

     &-65 {
        @include mq(tablets) {
            float: left;
            @include clearfix;
            flex: 0 1 65%;
            width: 65%;
            max-width: 65%;
            &:first-of-type {
                margin-right: 2em;
            }
        }
    }

}

.content-wrap-hp {
   .flex-child-img_bar {
    @include mq(tablets) {
        padding-right: 2em;
        margin: 0 auto;

    }
}
}


.flex-child-no_margin {
    @include mq(tablets) {
        float: left;
        width: 33%;
        max-width: 33%;
        flex: 0 0 33.34%;
        margin: 0;
    }
}

.color-bar {
    padding: 2em 1em;
    background: $color-blue-light url('/images/bkg-01.png') repeat;
    color: $color-white;
    @include mq($max-width) {
        background-attachment: fixed;
    }

    @include mq(73.25em) {
        padding: 2em 0;
    }

    h2:first-of-type {
        margin-bottom: 0;
        @include mq(tablets) {
            margin-bottom: 1em;
        }
    }
}

.color-bar-bottom_padding {
    padding-bottom: 4em;
}