.services-list {
    padding: 0;
    list-style: none;
}

.services-list_item {
    margin-bottom: 2em;
    padding-bottom: 2em;
    // border-bottom: 2px solid $color-blue;
}

.services-flex_wrap {
    @include mq(tablets) {
        display: flex;
        justify-content: space-between;
    }

}

.services-info {
    width: 100%;
    @include mq(tablets) {
        flex-basis: 60%;
        width: 60%;
        max-width: 60%;
    }

    p:first-of-type {
        margin-top: 0;
    }
}

.services-img {
    text-align: center;

    @include mq(tablets) {
        flex-basis: 38%;
        width: 38%;
        max-width: 38%;
        margin: 0;
        text-align: right;
    }

    img {
        display: inline-block;
        &:first-of-type {
            margin-top: 1em;
        }

        @include mq(tablets) {
           &:first-of-type {
            margin-top: 0;
            }
        }
    }
}

.services_list-name {
    margin: 0 0 1em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    border-top: 2px solid $color-grey-dark;
    border-bottom: 3px solid $color-grey-dark;
}

.services-top_link {
    float: right;
    padding-top: 2em;
}