.header {
    display: flex;
    background-color: $color-blue;
    color: $color-white;
    padding: 0.5em 0;
}

.header-phone {
    display: block;
    font-size: 1.25em;
    margin-bottom: 0.5em;
    font-weight: 700;
    color: $color-white;
}

.header-content {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    width: 100%;
    max-width: $max-width;
    margin: 0 auto;
    padding: 1em;

    @include mq(57em) {
        flex-flow: row nowrap;
        align-items: center;
    }

    @include mq($max-width) {
         padding: 0.5em 0;
    }

    a:hover {
        border: none;
    }
}

.header-content_wrap-right {

    width: 100%;
    .header-content {
        display: none;
        padding: 0;
        @include mq(57em) {
            display: inline-block;
            padding: 0;
        }
    }

    @include mq(57em) {
        float: right;
        width: auto;
    }
}

.header-logo {
    // float: left;
    width: 100%;
    text-align: center;
    @include mq(57em) {
        max-width: 10.75em;
        margin-right: 2em;
        text-align: left;
    }
}

.header-logo-small {
    display:none;
    @include mq(57em) {
        display: block;
        margin-right: auto;
    }

}

.header-nav {
    list-style: none;
    padding: 0;
    margin-bottom: 0;

    @include mq(wide_tablets) {
        display: flex;
        justify-content: flex-end;
        margin-top: 0;
    }
}

.header-nav_item {
    display: none;

    a, a:focus {
        text-decoration: none;
        transition: all 0.1s ease;
        color: $color-white;
    }

    a:hover {
        border-bottom: 1px solid $color-white;
    }

    @include mq(57em) {
        display: inline-block;
        margin-right: 2.5em;
        &:last-of-type, &:nth-last-child(2) {
            margin-right: 0;
        }


    }

}

.header-nav_item-mobile {
    display: none;
}

.header-social_bar {
    text-align: right;
    .social_icon-item-link {
        display: none;
        @include mq(57em) {
            display: inline-block;
            margin-top: 0;
            margin-bottom: 0.5em;
        }
    }
}

.header-menu {
    // float: right;
    display: block;
    margin-top: -2em;
    padding-right: 1em;
    @include clearfix;
    @include mq(57em) {
        display: none;
    }
}

.header-menu_icon {
    width: 1.75em;
    height: 1.75em;
    fill: $color-grey-light;
    transition: all 0.25s ease;
    text-decoration: none;

    &:hover {
        fill: lighten($color-grey-light, 10%);
    }
}

.header-mobile_open {
    margin-bottom: 0;

    .header-content_wrap-right .header-content {
        display: block;
    }
    .header-nav {
        width: 100%;
        max-width: 100%;
    }
    .header-nav_item {
        font-size: 1.2em;
        display: block;
        margin: 0.75em 0;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    .header-nav_item-mobile {
        display: block;
    }
}