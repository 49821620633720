.team-list {
    padding: 0;
    list-style: none;
}

.team-list_item {
    margin-bottom: 2em;
    padding-bottom: 2em;
    // border-bottom: 2px solid $color-blue;
}

.team-flex_wrap {
    @include mq(tablets) {
        display: flex;
        justify-content: space-between;
    }

}

.team-info {
    width: 100%;
    @include mq(tablets) {
        flex-basis: 60%;
        width: 60%;
        max-width: 60%;
    }

    p:first-of-type {
        margin-top: 0;
    }
}

.team-img {
    text-align: center;
    @include mq(tablets) {
        flex-basis: 38%;
        width: 38%;
        max-width: 38%;
        margin: 0;
        text-align: right;
    }

    img {
        display: inline-block;

    }
}

.team_list-name {
    margin: 0 0 1em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    border-top: 2px solid $color-grey-dark;
    border-bottom: 3px solid $color-grey-dark;
}