.subnav {
    padding: 0;
    list-style: none;
    @supports (display:grid) {
        display: grid;
        grid-gap: 10px;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }

}

.subnav-item {
    display: inline-block;
    justify-content: center;
    align-items: center;
    min-height: 3.5rem;
    margin-bottom: 1em;
    margin-right: 1em;
    padding: 0.25em 0.5em;
    border: 1px solid $color-blue;
    text-align: center;

    @supports (display:grid) {
        display: flex;
        margin-right: 0;
    }
}