.faq-list {
    padding: 0;
    list-style: none;
}

.faq-item {
    margin-bottom: 2em;
}

.faq-question {
    display: block;
    font-size: 1.2em;
    width: 100%;
    padding: 0.5em;
    background-color: $color-blue;
    color: $color-white;
    text-align: center;
}

.faq-answer {
    display: none;
}