.herobar {
    width: 100%;
    min-height: 30em;
    .herobar-overlay {
        min-height: 30em;
        background-color: rgba(0,0,0,0.3);
    }
    @include mq(wide_tablets) {
        height: 60vh;
    }
}

.herobar-overlay-hp {
    @include mq(tablets) {
        padding-top: 7em;
    }
}

.herobar-hp {
    background: #444 url('/images/herobar-dc_hp.jpg') no-repeat;
    background-size: cover;

     .herobar-overlay {
        height: 100%;
     }

    //position absolute fixes weird IE 9 bug
    position: absolute;
    top: 0;
    @include mq(wide_tablets) {
         height: 100vh;

         .herobar-overlay {
            height: 100vh;
        }
    }
}

.herobar-heading {
    font-size: 2.5em;
    text-align: center;
    @include mq(tablets) {
        font-size: 3.7em;
    }
}

.herobar-subheading {
    font-size: 1.7em;
    text-align: center;
    @include mq(tablets) {
        font-size: 2.3em;
    }
}

.herobar-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.2);
}

.hp_herobar-content-wrap {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    color: $color-white;
}

.herobar-content-wrap {
    max-width: 42em;
    padding: 0 1em;
    color: $color-white;

    @include mq(tablets) {
        padding: 0;
    }
}

.herobar-dale_charles {
    background: #444 url('../images/herobars/herobar-dc.jpg') no-repeat;
    background-position: center;
    background-size: cover;

}

.herobar-sports_clinic {
    background: #444 url('../images/herobars/herobar-sc.jpg') no-repeat;
    background-position: center;
    background-size: cover;
}

.herobar-faq {
    background: #444 url('../images/herobars/herobar-faq.jpg') no-repeat;
    background-position: center;
    background-size: cover;
}

.herobar-resources {
    background: #444 url('../images/herobars/herobar-resources.jpg') no-repeat;
    background-position: center;
    background-size: cover;

}

.herobar-services {
    background: #444 url('../images/herobars/herobar-services.jpg') no-repeat;
    background-position: center;
    background-size: cover;

}

.herobar-team {
    background: #444 url('../images/herobars/herobar-team.jpg') no-repeat;
    background-position: center;
    background-size: cover;
}

.herobar-contact {
    background: #444 url('../images/herobars/herobar-contact.jpg') no-repeat;
    background-position: bottom;
    background-size: cover;
}