.bios-wrap {
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-start;
}

.bios-flex-wrap {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    flex-basis: 100%;

    @include mq(wide_handhelds) {
        width: 55%;
        flex-basis: 55%;
        padding: 0.5em;
    }

    @include mq(tablets) {
        flex-basis: 25%;
        width: 25%;
        max-width: 25%;
        margin-right: 0;
    }
}

.bio-content {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 2em;
    padding: 1em;
    transition: all 0.25s ease;
    text-align: center;
    a {
        display: block;
        text-decoration: none;
    }

    > a {
        flex: 0 1 auto;
    }

}

.bio-wrap {
    flex-basis: 100%;
    width: 100%;
    max-width: 100%;
    max-height: 18em;
    min-height: 18em;

    @include mq(tablets) {
        margin-right: 0;
        filter: grayscale(100%);
        transition: all 0.5s ease;
        transition: filter 1s ease-out;
    }

    &:hover {
        filter: grayscale(0%);
        transition: filter 0.5s ease-out;
        .bio-content {
           opacity: 1;
           background: rgba(0,0,0,0.7);
        }
    }

    //fixes IE flexbox glitch
    > div {
        flex: 0 1 auto;
    }
}

.bio-name {
    margin: 0;
    padding-bottom: 1em;
    border-bottom: 1px solid #000;
}

.bio-description {
    padding-top: 0.5em;
}